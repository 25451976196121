import { MenuOutlined } from '@ant-design/icons'
import { Divider, Drawer } from 'antd'
import cn from 'classname'
import FunctionalButton from 'components/ButtonV2/FunctionalButton'
import NavigationalButton from 'components/ButtonV2/NavigationalButton'
import SpecialAlertBanner from 'components/SpecialAlertBanner'
import { useAuth } from 'components/auth'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { PAGE } from 'utils/pages'

const HeaderV2 = ({ locale }) => {
  const router = useRouter()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { createDemoAccount, authUser } = useAuth()
  const { t: navTranslations } = useTranslation('nav')
  const { t: offerTranslations } = useTranslation('offer')

  const handleLoginClick = (href) => {
    router.push(href)
  }
  const countDownDate = new Date('2024-10-14 23:59:59').getTime()
  const showCountDown = countDownDate > new Date().getTime()

  const specialAlertProps = {
    mainText: offerTranslations('new_product_banner'),
    countdownText: offerTranslations('new_product_banner_offer_ends_in'),
    countdownEndDate: countDownDate,
    countdownFormat: offerTranslations('new_product_banner_format'),
    buttonText: offerTranslations('new_product_banner_learn_more'),
    buttonHref: PAGE.wbsoApplicationIntro,
  }

  const handleLogginUser = () => {
    if (authUser?.has_access_application && !authUser?.has_access_admin) {
      return (
        <FunctionalButton onClick={() => router.push(PAGE.wbsoAppDashboard)}>
          Dashboard
        </FunctionalButton>
      )
    } else if (authUser?.has_access_admin) {
      return (
        <FunctionalButton onClick={() => router.push(PAGE.dashboard)}>
          Dashboard
        </FunctionalButton>
      )
    } else {
      throw new Error('User does not have access to any page.')
    }
  }

  const HEADER_MENU = [
    { text: navTranslations('nav_home'), link: PAGE.landing, adminOnly: false },
    {
      text: navTranslations('nav_wbso_application'),
      link: PAGE.wbsoApplication,
      adminOnly: false,
    },
    {
      text: navTranslations('nav_how_it_works'),
      link: `${PAGE.landing}#how-it-works`,
      adminOnly: true,
    },
    {
      text: 'Case Study',
      link: `${PAGE.landing}#case-study`,
      adminOnly: true,
    },
    {
      text: navTranslations('nav_pricing'),
      link: `${PAGE.landing}#pricing`,
      adminOnly: true,
    },
    { text: navTranslations('nav_blog'), link: PAGE.blog, adminOnly: false },
    {
      text: navTranslations('nav_wbso_calculator'),
      link: PAGE.wbsoCalculator,
      adminOnly: false,
    },
    {
      text: navTranslations('nav_wbso_knowledge_base'),
      link: locale === 'en' ? '/en/wbso-knowledge-base' : '/nl/wbso-kennisbank',
      adminOnly: false,
    },
  ]

  const menu_to_show = HEADER_MENU.filter((d) => {
    if (router.pathname !== '/') {
      return !d.adminOnly
    }
    return d
  })

  return (
    <>
      <header
        className={cn(
          'w-full z-10 flex items-center justify-between px-6 py-4 border-b border-[#F2F3F7] fixed top-0 bg-white shadow',
          'lg:px-[100px]',
        )}
      >
        <div className="flex items-center gap-x-10 lg:gap-x-16">
          <span className="font-sfui block h-10 text-2xl font-extrabold md:text-[32px]">
            Traqqie
          </span>
          <ul className="w-full hidden items-center gap-x-8 mb-0 md:flex">
            {menu_to_show.map(({ text, link }) => {
              return (
                <li key={text}>
                  <Link key={link} href={link} passHref>
                    <a
                      className={cn(
                        'text-sm font-medium text-black font-sfui leading-none hover:text-g400',
                      )}
                    >
                      {text}
                    </a>
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>

        <div className="items-center justify-end hidden gap-x-2 md:flex">
          {!authUser ? (
            <>
              <NavigationalButton
                variant="outlined"
                onClick={() => handleLoginClick(PAGE.signin)}
                href={PAGE.signin}
              >
                Login
              </NavigationalButton>
              <NavigationalButton
                onClick={() => createDemoAccount()}
                href={PAGE.signin}
              >
                Free Demo
              </NavigationalButton>
            </>
          ) : (
            handleLogginUser()
          )}
        </div>

        <button
          className="bg-transparent px-3 md:hidden"
          onClick={() => setIsMenuOpen(true)}
        >
          <MenuOutlined size={24} className="text-[#475467]" />
        </button>
        <Drawer open={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
          <ul className="w-full flex flex-col items-center gap-y-6">
            {menu_to_show.map(({ text, link }) => {
              return (
                <li key={link}>
                  <Link
                    key={link}
                    href={link}
                    className={cn('text-sm font-medium text-black font-sfui')}
                  >
                    {text}
                  </Link>
                </li>
              )
            })}
          </ul>
          <Divider />
          <div className="w-full flex flex-col items-center gap-y-3 mt-3">
            <NavigationalButton
              className="!w-36"
              onClick={() => handleLoginClick(PAGE.signin)}
              href={PAGE.signin}
            >
              Free Demo
            </NavigationalButton>
            <NavigationalButton
              variant="outlined"
              className="!w-36"
              onClick={() => handleLoginClick(PAGE.signin)}
              href={PAGE.signin}
            >
              Login
            </NavigationalButton>
          </div>
        </Drawer>
      </header>
      {showCountDown && <SpecialAlertBanner {...specialAlertProps} />}
    </>
  )
}

export default HeaderV2
