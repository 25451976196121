import cn from 'classname'

const PartnerSection = ({ title, partners }) => {
  return (
    <section
      id="partner"
      className="flex flex-col items-center py-12 px-6 gap-y-12 md:py-24 lg:px-[100px]"
    >
      <h2 className="font-inter font-medium text-base text-gr600 text-center">
        {title}
      </h2>
      <ul
        className={cn(
          'w-full flex flex-col items-center gap-y-10 flex-wrap',
          'sm:gap-x-8 sm:flex-row sm:justify-center sm:items-start',
          'xl:gap-x-16',
        )}
      >
        {partners.map(({ text, path, width, height }) => {
          return (
            <img
              key={text}
              width={width}
              height={height}
              alt={text}
              src={path}
              className="h-8 w-auto object-cover block lg:h-12"
            />
          )
        })}
      </ul>
    </section>
  )
}

export default PartnerSection
