import { Statistic } from 'antd'
import { useAuth } from 'components/auth'
import NavigationalButton from 'components/ButtonV2/NavigationalButton'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { PAGE } from 'utils/pages'
const { Countdown } = Statistic

const SpecialAlertBanner = ({
  translations,
  backgroundColor = '#C83232',
  textColor = 'white',
  mainText,
  countdownText,
  countdownEndDate,
  countdownFormat,
  buttonText,
  buttonHref,
}) => {
  const [isClient, setIsClient] = useState(false)
  const router = useRouter()
  const { authUser } = useAuth()

  useEffect(() => {
    setIsClient(true)
  }, [])

  return (
    <div
      className={`py-3 px-4 text-center sticky top-[72px] md:top-20 z-10`}
      style={{ backgroundColor, color: textColor }}
    >
      <div className="container mx-auto flex flex-col sm:flex-row items-center justify-center">
        <span className="mb-2 sm:mb-0">
          {mainText}
          {countdownEndDate && countdownText && (
            <>
              {' - '}
              {countdownText}{' '}
              {isClient ? (
                <Countdown
                  value={countdownEndDate}
                  format={countdownFormat}
                  className="inline-block"
                  style={{
                    color: 'inherit !important',
                    fontSize: 'inherit !important',
                    fontWeight: 'inherit !important',
                    lineHeight: 'inherit !important',
                    fontFamily: 'inherit !important',
                  }}
                />
              ) : (
                <span>Loading...</span>
              )}
            </>
          )}
        </span>
        {buttonText && buttonHref && (
          <NavigationalButton
            variant="outlined"
            onClick={() => {
              if (!authUser) {
                router.push(buttonHref)
              } else {
                router.push(PAGE.wbsoAppDashboard)
              }
            }}
            href={buttonHref}
            className="ml-0 sm:ml-4 bg-white text-black px-3 py-1 rounded-full text-sm"
          >
            {buttonText}
          </NavigationalButton>
        )}
      </div>
    </div>
  )
}

export default SpecialAlertBanner
